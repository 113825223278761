<template>
  <section
    style="max-width: 982px !important; margin: 0 auto"
    class="pa-4"
    v-if="fair"
    >
    <main-header
      :fair="fair"
      :resolution="$vuetify.breakpoint.mdAndUp ? 'desktop' : 'mobile'"
      ></main-header>

    <random-stores
      :fair="fair"
      ></random-stores>

    <sponsors
      :fair="fair"
      ></sponsors>

    <stores
      :fair="fair"
      ></stores>

    <div
      class="d-flex flex-column align-center justify-center py-6 px-6 my-12 white rounded-lg elevation-0"
      >
      <div
        class="text-h6 font-weight-bold primary--text"
        >
        CON LA SEGURIDAD Y CONFIANZA DE PAGAR CON
      </div>

      <v-img
        contain
        width="200"
        :src="require('@/assets/img/webpay.png')"
        ></v-img>
    </div>
  </section>
</template>

<script>
import { Fair } from '@/graphql/queries/fairs/fairs'

import MainHeader from '@/components/fairs/public/Header'

const Sponsors = () => import('@/components/fairs/public/Sponsors')
const Stores = () => import('@/components/fairs/public/Stores')
const RandomStores = () => import('@/components/fairs/public/RandomStores')

export default {
  data: () => ({
    fair: null,
  }),

  created () {
    this.fetchFair()

    localStorage.setItem('orderFairId', this.$route.params.id)
  },

  methods: {
    fetchFair () {
      this.$apollo.query({
        query: Fair,
        variables: {
          id: this.$route.params.id
        }
      }).then( res => {
        this.fair = res.data.fair
      })
    },
  },

  components: {
    MainHeader,
    Sponsors,
    Stores,
    RandomStores,
  }
}
</script>
