<template>
  <div
    class="mb-3"
    >
    <v-carousel
      class="rounded-lg fill-width"
      hide-delimiters
      cycle
      :show-arrows="!$vuetify.breakpoint.mobile && banners.length > 1"
      :style="$vuetify.breakpoint.smAndDown ? 'max-height: 200px' : 'max-height: 300px'"
      height="auto"
      >
      <v-carousel-item
        v-for="banner in banners"
        :src="url + banner.imageUrl"
        ></v-carousel-item>
    </v-carousel>

    <div
      class="d-flex flex-column align-center justify-center mt-md-n14 mt-n5"
      >
      <v-avatar
        :size="$vuetify.breakpoint.mdAndUp ? 160 : 120"
        >
        <v-img
          :src="url + fair.image.url"
          ></v-img>
      </v-avatar>

      <div
        class="text-md-h3 text-h4 font-weight-bold primary--text mt-3"
        >
        {{ fair.name }}
      </div>

      <v-icon
        x-large
        color="primary"
        >
        mdi-string-lights
      </v-icon>

      <div
        class="mt-3 text-subtitle-1 font-weight-regular black--text"
        >
        Desde <span class="font-weight-medium">{{ (new Date(fair.startsAt)).toLocaleDateString() }}</span> hasta <span class="font-weight-medium">{{ (new Date(fair.endsAt)).toLocaleDateString() }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    fair: {
      type: Object,
      required: true
    },

    resolution: {
      type: String,
      required: true
    }
  },

  computed: {
    ...mapGetters(['url']),

    banners () {
      return this.fair.banners.filter( banner => banner.bannerType == this.resolution ) 
    },
  }
}
</script>
